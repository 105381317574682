import { Injectable, inject } from '@angular/core';
import { RestApiCall } from './rest-api-call';
import {
  AuthInformation,
  ChangePasswordPayload,
  LoginCredential,
  RegistrationPayload,
  RequestResetPasswordLinkPayload,
  SetNewPasswordPayload,
  UserProfile,
} from '../types/auth';
import { BehaviorSubject, Observable } from 'rxjs';
import { Nullable } from '../types/common';
import { Router } from '@angular/router';
import { ShopService } from './shop.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends RestApiCall {
  private _authInfo = new BehaviorSubject<Nullable<AuthInformation>>(null);
  private _router = inject(Router);
  private _shopService = inject(ShopService);
  private readonly AUTH_INFO_STORAGE_KEY = 'authInfo';

  constructor() {
    super();
    this.setAuthInfoFromStorage();
  }

  register(registrationPayload: RegistrationPayload) {
    return this._http.post<RegistrationPayload>(
      this.prepareUrl('/register'),
      registrationPayload
    );
  }

  login(credential: LoginCredential) {
    return this._http.post<AuthInformation>(
      this.prepareUrl('/login'),
      credential
    );
  }

  logout(doReload = false) {
    localStorage.removeItem(this.AUTH_INFO_STORAGE_KEY);
    this._authInfo.next(null);

    if (doReload) {
      window.location.reload();
    }
  }

  setAuthInfo(authInfo: AuthInformation) {
    this._authInfo.next(authInfo);
  }

  setAuthInfoInStorage(authInfo: AuthInformation) {
    localStorage.setItem(this.AUTH_INFO_STORAGE_KEY, JSON.stringify(authInfo));
  }

  getAuthInfo() {
    return this._authInfo;
  }

  private setAuthInfoFromStorage() {
    const authInfo = localStorage.getItem(this.AUTH_INFO_STORAGE_KEY);
    if (authInfo) {
      this.setAuthInfo(JSON.parse(authInfo) as AuthInformation);
    } else {
      this.logout();
    }
  }

  getProfile(userId: string) {
    return this._http.get<UserProfile>(this.prepareUrl(`/users/${userId}`));
  }

  updateProfile(userId: string, userProfile: Partial<UserProfile>) {
    return this._http.put(this.prepareUrl(`/users/${userId}`), userProfile);
  }

  changePassword(userId: string, payload: ChangePasswordPayload) {
    return this._http.put(
      this.prepareUrl(`/changePassword/${userId}`),
      payload
    );
  }

  requestResetPasswordLink(payload: RequestResetPasswordLinkPayload) {
    return this._http.put(
      this.prepareUrl(`/requestResetPasswordLink`),
      payload
    );
  }

  setNewPassword(payload: SetNewPasswordPayload) {
    return this._http.put(this.prepareUrl(`/setNewPassword`), payload);
  }

  sendVerificationLink(payload: { email: string }) {
    return this._http.post(this.prepareUrl(`/sendVerifyEmailLink`), payload);
  }

  verifyEmail(userId: string) {
    return this._http.get(this.prepareUrl(`/verifyEmail/${userId}`));
  }

  checkIsLoggedInAndRedirect(): boolean {
    // if auth information is available then don't redirect
    if (!!this.getAuthInfo().value) {
      return true;
    }
    this._router.navigateByUrl(
      `/shop/${this._shopService.selectedShop.value?._id}/auth`
    );
    return false;
  }
}
