import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { RestApiCall } from 'src/app/shared/api/rest-api-call';
import { Shop } from 'src/app/shops/types/shop';
import { Nullable } from '../types/common';
import { PaginatedResponse } from '../types/api';

@Injectable({
  providedIn: 'root',
})
export class ShopService extends RestApiCall {
  selectedShop = new BehaviorSubject<Nullable<Shop>>(null);

  private shopLinks: { [key: string]: Partial<Shop> } = {
    'north-myrtle-beach': {
      facebookLink: 'https://www.facebook.com/blueberrysgrillNMB/',
      yelpLink:
        'https://www.yelp.com/waitlist/blueberrys-grill-north-myrtle-beach',
      mapLink:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3317.3613206647456!2d-78.8179637241233!3d33.75132783355931!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x890065c1fe3e5823%3A0xb0fe33be61769a3f!2sBlueberry's%20Grill!5e0!3m2!1sen!2sin!4v1690919833938!5m2!1sen!2sin",
    },
    'wilmington-nc': {
      facebookLink: 'https://www.facebook.com/Blueberrysgrillwilmington',
      yelpLink: 'https://www.yelp.com/waitlist/blueberrys-grill-wilmington',
      mapLink:
        'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3298.9986210166485!2d-77.82555998255616!3d34.22305360000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89a9f36fa6ec8fe9%3A0xeab4d863389b9390!2s1900%20Eastwood%20Rd%2C%20Wilmington%2C%20NC%2028403%2C%20USA!5e0!3m2!1sen!2s!4v1687258348559!5m2!1sen!2s',
    },
    myrtle: {
      facebookLink: 'https://www.facebook.com/blueberrysgrill/',
      yelpLink: 'https://www.yelp.com/waitlist/blueberrys-grill-myrtle-beach-2',
      mapLink:
        'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d424627.4033472163!2d-78.816048!3d33.750287!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x890065ea041f64ef%3A0xd2aa84391ec65201!2s7931%20N%20Kings%20Hwy%2C%20Myrtle%20Beach%2C%20SC%2029572!5e0!3m2!1sen!2sus!4v1655466240820!5m2!1sen!2sus',
    },
    'north-myrtle-beach-2': {
      facebookLink: 'https://www.facebook.com/blueberrysgrillNMB/',
      yelpLink:
        'https://www.yelp.com/waitlist/blueberrys-grill-north-myrtle-beach-2',
      mapLink:
        'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3315.491154885957!2d-78.73949722544562!3d33.79963613102849!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8900635b14f2419b%3A0xade7b72440a7b977!2s4586%20Hwy%2017%20S%2C%20North%20Myrtle%20Beach%2C%20SC%2029582%2C%20USA!5e0!3m2!1sen!2s!4v1687258103257!5m2!1sen!2s',
    },
  };

  getShopList(): Observable<Shop[]> {
    return this._http
      .get<PaginatedResponse<Shop, 'shops'>>(this.prepareUrl('/shops'))
      .pipe(
        map((paginatedShopRes) =>
          paginatedShopRes.shops?.map((shop) => {
            const shopNameSlug = this.getShopNameSlug(shop.name);

            return {
              ...shop,
              imageUrl: this.getShopImageUrl(shop),
              ...this.shopLinks[shopNameSlug],
              instagramLink: 'https://www.instagram.com/blueberrygrill/',
            };
          })
        )
      );
  }

  getShopImageUrl(shop: Shop): string {
    return `assets/img/location_mb_${this.getShopNameSlug(shop.name)}.jpg`;
  }

  getShopNameSlug(name: string) {
    return name.toLowerCase().replaceAll(/[\W]+/g, '-');
  }
}
